import { useProfileStore } from './ProfileStore.ts'
import { useSnapshot } from 'valtio'
import { useNavigate, useLocation } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { FormattedMessage } from 'react-intl'
import {
  planInfos,
  SubscriptionContent,
} from '../onboarding/subscriptionView.tsx'
import { cn, useMount } from '../../shared/lib/utils.ts'
import { SubscriptionPlan } from '../../shared/api/chatApi.ts'

interface ProfileSubscriptionDrawerState {
  openDrawer: boolean
}

export const ProfileSubscription = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const subscription = state.subscription
  const location = useLocation()
  const { openDrawer = false } =
    (location.state as ProfileSubscriptionDrawerState | null) ?? {}

  useMount(() => {
    if (openDrawer) {
      store.setShowChangeSubscription(true)
    }
  })

  return (
    <div className="flex flex-col gap-16">
      <ProfileTitle title={<FormattedMessage id="user.subscription" />} />
      <ProfileMenuBlock>
        <ProfileButton
          withArrow
          onClick={() => {
            store.setShowChangeSubscription(true)
          }}
          title={<FormattedMessage id="user.changeSubscription" />}
          subtitle={
            state.subscription?.recurrent ? (
              <FormattedMessage
                id="recurrent"
                values={{
                  count: planInfos[state.subscription.plan].month,
                }}
              />
            ) : (
              ''
            )
          }
        />
      </ProfileMenuBlock>
      {subscription?.recurrent && (
        <ProfileMenuBlock>
          <ProfileButton
            alertTitle
            onClick={() => {
              store.setShowCancelSubscription(true)
            }}
            title={<FormattedMessage id="user.cancelSubscription" />}
          />
        </ProfileMenuBlock>
      )}

      <div className="mb-16 flex flex-col items-center text-gray7">
        <div className="text-12">
          <>
            <FormattedMessage
              id="payment.change"
              values={{
                contacts: <FormattedMessage id="contacts.title" />,
                telegram: (
                  <a className="underline" href="https://t.me/edman_ai">
                    @edman_ai
                  </a>
                ),
              }}
            />
          </>
        </div>
      </div>

      <CancelSubscriptionDrawer />
      <ChangeSubscriptionDrawer />
    </div>
  )
}

function CancelSubscriptionDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  return (
    <ProfileDrawer
      title={<FormattedMessage id="user.cancelSubscription?" />}
      open={state.showCancelSubscription}
    >
      <div className="flex flex-col gap-16">
        <div>
          <FormattedMessage id="user.areYouSureCancel" />
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            void store.unsubscribe()
          }}
        >
          <FormattedMessage id="yes" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowCancelSubscription(false)
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}

export const ChangeSubscriptionDrawer = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)

  return (
    <ProfileDrawer
      title={<FormattedMessage id="user.subscription" />}
      open={state.showChangeSubscription}
    >
      <div className="flex flex-col gap-16">
        <div className="group mb-24 bg-white">
          <div className="lg:flex-row relative flex-col gap-2">
            <Plan level="B1" planType="1M" />
            <Plan level="B1" planType="12M" />
          </div>
          <div className="mb-16 flex flex-col text-gray7">
            <div className="text-center text-12">
              <FormattedMessage id="subscription.change" />
            </div>
          </div>
        </div>
      </div>
    </ProfileDrawer>
  )
}

function PlanButton(props: { planType: SubscriptionPlan }) {
  const store = useProfileStore()
  const navigate = useNavigate()

  const handleClick = () => {
    store.setSubscriptionPlan(store.state.plan)
    void store.updateSubscriptionn()
    navigate(urls.profile('postChangeSubscription'))
  }

  return (
    <>
      <div className="my-12 sm:my-12">
        <Button
          size="custom"
          rounded="full"
          className={'mx-auto mb-8 h-52 w-full px-16 sm:h-72 sm:w-[340px]'}
          id={'subscription_trial_' + props.planType}
          bg="blue"
          onClick={handleClick}
        >
          <span className="flex flex-col gap-2">
            <span className="text-24 font-extrabold leading-none">
              <FormattedMessage id="activate" />
            </span>
          </span>
        </Button>
      </div>
    </>
  )
}

function Plan(props: { planType: SubscriptionPlan; level: string }) {
  const planType = props.planType
  const planInfo = planInfos[planType]
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const selectedPlan = planType == state.plan
  const subscriptionPlan = planType == state.subscription?.plan

  return (
    <div className="mt-16 overflow-hidden rounded">
      {planInfo.discount && (
        <div className="flex h-32 items-center justify-center bg-blue4 text-10 font-extrabold uppercase text-white">
          экономия {planInfo.discount}
        </div>
      )}
      <div
        className={cn(
          'cursor-pointer overflow-hidden bg-gray-light p-24 transition-colors',
          selectedPlan ? 'border-blue4' : 'border-transparent',
          planInfo.discount
            ? 'rounded-b border-x-4 border-b-4'
            : 'rounded border-4',
        )}
        onClick={() => {
          store.setPlan(props.planType)
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center gap-24">
            <div
              className={cn(
                'size-24 shrink-0 rounded-full transition-all',
                selectedPlan ? 'border-5 border-blue4' : 'border-1 border-gray',
              )}
            ></div>
            <div className="flex-1">
              <div className="text-20 font-extrabold leading-[32px]">
                <FormattedMessage
                  id="Months"
                  values={{ count: planInfo.duration }}
                />
              </div>
            </div>
          </div>
          <div className="text-24 font-extrabold text-blue4">
            {planInfo.price.ru}₽
          </div>
        </div>

        <AnimatePresence>
          {selectedPlan && (
            <motion.div
              className="overflow-hidden"
              initial={{ opacity: 0, height: '0' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0' }}
            >
              {!subscriptionPlan && <PlanButton planType={props.planType} />}
              <SubscriptionContent planType={planType} level={props.level} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export const PostChangeSubscription = () => {
  const navigate = useNavigate()
  const imageUrl = '/images/party-popper.png'

  return (
    <div className="flex w-full flex-1 items-stretch justify-center bg-white p-16 pb-100 sm:items-center">
      <div className="flex w-full max-w-800 flex-col items-center justify-between rounded">
        <img src={imageUrl} alt="status-img" className="size-[228px]" />
        <div className="mt-42 text-center text-32 font-extrabold">
          {<FormattedMessage id="subscription.change_success" />}
        </div>
        <div className="mb-16 flex flex-col items-center font-medium leading-8 text-gray6">
          <div className="text-center text-18 ">
            <div className="mt-24">
              <FormattedMessage
                id="payment.contacts"
                values={{
                  contacts: <FormattedMessage id="contacts.title" />,
                  telegram: (
                    <a className="underline" href="https://t.me/edman_ai">
                      @edman_ai
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <Button
          bg="blue-gradient"
          size="large"
          rounded="full"
          className="fixed bottom-100 z-50 mx-16 w-full max-w-[calc(100%-32px)] sm:w-800"
          onClick={() => {
            navigate(urls.index)
          }}
        >
          {<FormattedMessage id="Continue" />}
        </Button>
      </div>
    </div>
  )
}
