import { useAppModule } from '../appContext.ts'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { cn } from '../../shared/lib/utils.ts'
import React from 'react'
import { useSnapshot } from 'valtio/react'
import { FormattedMessage } from 'react-intl'
import { LogoImg } from '../../shared/ui/images/images.tsx'

const menuItems = [
  {
    text: 'navbar.lessons',
    icon: 'navStar',
    url: urls.learningProfile(),
  },
  {
    text: 'navbar.vocabPlus',
    icon: 'book',
    url: urls.practice,
  },
  {
    text: 'navbar.progress',
    icon: 'trendUp',
    url: urls.progress,
  },
  {
    text: 'navbar.profile',
    icon: 'profile2',
    url: urls.profile(),
  },
] as const

const navBarClassName = (params: { isActive: boolean }) =>
  cn(
    'flex items-center gap-12 rounded-8 px-12 py-8 transition-colors',
    params.isActive ? 'bg-second-light text-blue2' : 'text-black opacity-70',
  )

export const Menu = (props: { className?: string }) => {
  const authStore = useAppModule().authStore
  const authState = useSnapshot(authStore.state)
  const isAnonOrLogout = authState.isAnon || !authState.isLogin
  return (
    <div
      className={cn(
        'flex min-h-dvh w-[230px] shrink-0 cursor-default flex-col bg-white text-12 font-semibold shadow-menu ',
        props.className,
      )}
    >
      <div className="fixed inset-y-0 left-0 flex min-h-dvh flex-col justify-between px-16 pb-40 pt-20">
        <div className="mb-40">
          <Link to="/">
            <LogoImg className="pl-12" />
          </Link>
          <div className="mt-32 flex flex-col gap-8">
            {menuItems.map((item) => (
              <NavLink
                key={item.text}
                to={item.url}
                className={navBarClassName}
              >
                <Icon className="shrink-0" size="sm" iconName={item.icon} />
                <FormattedMessage id={item.text} />
              </NavLink>
            ))}
          </div>
        </div>
        {isAnonOrLogout ? null : (
          <div>
            <div className="mb-12 ml-12 text-10 font-extrabold uppercase text-light">
              <FormattedMessage id="Option" />
            </div>
            <div className="flex flex-col gap-8">
              <NavLink className={navBarClassName} to={urls.contacts}>
                <Icon iconName="help" size="sm" />{' '}
                <FormattedMessage id="Help" />
              </NavLink>

              <LoginLogout />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function LoginLogout() {
  const authStore = useAppModule().authStore
  const navigate = useNavigate()
  const state = useSnapshot(authStore.state)
  const logout = state.isLogin && !state.isAnon

  return (
    <button
      data-testid="logout-btn"
      className={navBarClassName({ isActive: false })}
      onClick={() => {
        if (logout) {
          authStore.logoutAndRefresh()
        } else {
          navigate(urls.login)
        }
      }}
    >
      <Icon iconName="exit" size="sm" />
      <FormattedMessage id={logout ? 'Log out' : 'Log in'} />
    </button>
  )
}
