export const TYPES_TIME_MAP = {
  'Warm Up': 3,
  Reading: 4,
  Vocabulary1: 2,
  Vocabulary2: 4,
  Grammar1: 3,
  Grammar2: 4,
  Listening: 2,
  'Role play': 5,
  Challenges: 5,
  Sound: 2,
  Match: 2,
  Choice: 3,
  'Big word': 2,
  'Play your game': 2,
  'Context Quest': 4,
}

export const TYPES_WITH_SOUND = [
  'Vocabulary1',
  'Listening',
  'Sound',
  'Big word',
  'Play your game',
]
