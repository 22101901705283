import React from 'react'
import { useIntl } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { Icon, IconName } from '../../shared/ui/icon/icon.tsx'
import { classed } from '@tw-classed/react'
import { CustomBookSvg } from '../../shared/ui/customIcons/customBookSvg.tsx'
import { urls } from '../../shared/urls.ts'
import { useLocation, useNavigate } from 'react-router-dom'

export interface IUsualIcon {
  text: AppMessageKeys
  type: 'icon'
  icon: IconName | 'message'
  url: string
}

const navbarItems: IUsualIcon[] = [
  {
    text: 'navbar.lessons',
    type: 'icon',
    icon: 'navStar',
    url: urls.learningProfile(),
  },
  {
    text: 'navbar.vocabPlus',
    icon: 'message',
    type: 'icon',
    url: urls.practice,
  },
  {
    text: 'navbar.progress',
    icon: 'trendUp',
    type: 'icon',
    url: urls.progress,
  },
  {
    text: 'navbar.profile',
    icon: 'profile2',
    type: 'icon',
    url: urls.profile(),
  },
]

const NavButton = classed.div(
  'flex cursor-pointer flex-col items-center gap-4',
  {
    variants: {
      isActive: {
        true: 'text-white opacity-100',
        false: 'text-black opacity-25',
      },
    },
  },
)

const NavImgWrapper = classed.div(
  'flex size-44 items-center justify-center rounded-12',
  {
    variants: {
      isActive: {
        true: 'bg-blue-gradient2 text-white shadow-inset-button',
        false: 'text-black',
      },
    },
  },
)

const ClassedText = classed.p('text-12 font-extrabold', {
  variants: { isActive: { true: 'text-blue3', false: 'text-black' } },
})
const getCurrentIconOrText = (item: IUsualIcon, isActive: boolean) => {
  return item.icon === 'message' ? (
    <CustomBookSvg mainColor={isActive ? '#ffffff' : '#000000'} />
  ) : (
    <Icon iconName={item.icon} size="sm" />
  )
}

export function Navbar() {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const getIsActive = (url: string) => {
    return location.pathname.includes(url) && url !== ''
  }

  return (
    <div className="fixed bottom-0 z-50 flex h-[100px] w-full justify-between bg-white px-32 pt-22 sm:left-1/2 sm:mx-auto sm:w-[800px] sm:-translate-x-1/2 sm:rounded-t-32">
      {navbarItems.map((item) => {
        const isActive = getIsActive(item.url)
        return (
          <NavButton
            isActive={isActive}
            onClick={() => {
              navigate(item.url)
            }}
            key={item.text}
            id={item.text}
          >
            <NavImgWrapper isActive={isActive}>
              {getCurrentIconOrText(item, isActive)}
            </NavImgWrapper>
            <ClassedText isActive={isActive}>
              {intl.formatMessage({ id: item.text })}
            </ClassedText>
          </NavButton>
        )
      })}
    </div>
  )
}
