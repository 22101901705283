import { FormattedMessage, useIntl } from 'react-intl'
import { usePromise } from '../shared/lib/hooks.ts'
import { Loadable } from '../shared/ui/loadable.tsx'
import {
  MetricsCards,
  ProgressMetricsWrapper,
  ProgressPageHeader,
  ProgressPageHeaderData,
} from '../features/progress/progressView.tsx'
import { useAppModule } from '../features/appContext.ts'

export function ProgressPage() {
  const intl = useIntl()

  const appModule = useAppModule()
  const params = usePromise(() =>
    Promise.all([
      appModule.chatsApi.getAccount(),
      appModule.progressStore.getAllMetrics(),
      appModule.practiceApi.getVocabStat(),
      appModule.practiceApi.getCount(),
    ]),
  )

  return (
    <div className="flex w-full flex-col p-20 pt-6">
      <ProgressPageHeader>
        {intl.formatMessage({ id: 'metrics.yourProgress' })}
      </ProgressPageHeader>
      <Loadable data={params}>
        {(data) => {
          return (
            <div className="mb-100 xl:mb-0">
              <ProgressPageHeaderData
                username={data[0].name}
                dateJoined={data[0].date_joined}
              />
              <MetricsCards />
              <p className="mx-12 mt-22 text-center text-14 font-normal text-gray4">
                <FormattedMessage
                  id="metrics.footerText"
                  values={{
                    boldText: (
                      <span className="font-bold">
                        {intl.formatMessage({ id: 'metrics.footerBoldSpan' })}
                      </span>
                    ),
                  }}
                />
              </p>
              <ProgressMetricsWrapper
                graph={data[2].graph}
                wordsCount={data[3]}
              />
            </div>
          )
        }}
      </Loadable>
    </div>
  )
}
