import { proxy } from 'valtio'
import { SpeechService } from '../speech/speechService.ts'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { WordsApi } from '../../shared/api/wordsApi.ts'
import { Vocabulary } from '../../shared/api/chatApi.ts'
import { emptyFn } from '../../shared/lib/utils.ts'
import { PracticeApi } from 'src/shared/api/practiceApi.ts'

export type Filters = 'all' | 'byDate' | 'added'

export interface Word {
  explain: string
  id: number
  is_user_added: boolean
  program_tag: null | string
  rating: number
  timestamp: string
  translation: string
  word_normal: string
  word_original: string
}

interface State {
  addWordDrawer: {
    open: boolean
    loading: boolean
    word: string
  }
  words: {
    loading: boolean
    list: Vocabulary[] | null
  }
  filter: {
    selector: Filters
  }
  checkedWords: string[]
  playingWordId: number | null
  totalWords: number | null
}

const defaultState = (): State => ({
  addWordDrawer: {
    open: false,
    loading: false,
    word: '',
  },
  words: {
    loading: false,
    list: null,
  },
  filter: {
    selector: 'all',
  },
  checkedWords: [],
  playingWordId: null,
  totalWords: null,
})
export class WordsStore {
  state: State

  constructor(
    private speechService: SpeechService,
    private wordsApi: WordsApi,
    private practiceApi: PracticeApi,
  ) {
    this.state = proxy<State>(defaultState())
  }

  setAddWordDrawerOpen(open: boolean) {
    this.state.addWordDrawer.open = open
  }

  setWord(word: string) {
    this.state.addWordDrawer.word = word
  }

  async getAllWords() {
    try {
      this.state.words.loading = true
      const selector = this.state.filter.selector
      await this.getStat()
      const request: Record<string, string | boolean> = {}
      if (selector === 'added') {
        request.is_user_added = true
      } else if (selector === 'byDate') {
        request.ordering = '-timestamp'
      }

      const words = await this.wordsApi.getWords(request)
      this.state.words = { list: words, loading: false }
    } catch (e) {
      console.log('Error fetching words:', e)
    } finally {
      this.state.words.loading = false
      this.state.checkedWords = []
    }
  }

  async getStat() {
    try {
      this.state.totalWords = (await this.practiceApi.getVocabStat()).total
    } catch (e) {
      console.log('Error fetching stat:', e)
    }
  }

  async deleteWord(id: number) {
    try {
      this.state.words.loading = true
      await this.wordsApi.wordRemove(id)
    } catch (e) {
      console.log(e)
    } finally {
      await this.getAllWords()
    }
  }

  async addWordToVocabulary() {
    try {
      this.state.addWordDrawer.loading = true
      await this.wordsApi.wordAdd({
        word_original: this.state.addWordDrawer.word,
      })
      this.state.addWordDrawer = { open: false, word: '', loading: false }
      await this.getAllWords()
    } catch (e) {
      console.log(e)
    } finally {
      this.state.addWordDrawer.loading = false
    }
  }
  setFilter(filter: Filters) {
    this.state.filter.selector = filter
    void this.getAllWords()
  }
  toggleCheckedWords(word: string) {
    const words = this.state.checkedWords
    words.includes(word)
      ? (this.state.checkedWords = words.filter((el) => el !== word))
      : words.length !== 5 && (this.state.checkedWords = [...words, word])
  }

  deselectWords() {
    this.state.checkedWords = []
  }

  initSpeechService() {
    if (!this.speechService.inited) {
      this.speechService.init()
    }
  }

  playWord(word: string, wordId: number) {
    this.setPlayingWordId(wordId)
    this.speechService.stopCurrentSound()
    void this.speechService.playText(
      undefined,
      undefined,
      word,
      emptyFn,
      emptyFn,
      () => {
        this.setPlayingWordId(null)
      },
    )
  }

  setPlayingWordId(id: number | null) {
    this.state.playingWordId = id
  }
}

export const WordsStoreContext = createStoreContext<WordsStore>()

export function useWordsStore() {
  return useStoreContext(WordsStoreContext)
}
