import { useIsMobile, useLazyRef } from '../../shared/lib/hooks.ts'
import {
  AddWordDrawer,
  ChatStoreContext,
  ChatView,
  HintView,
  messageStageElementId,
  useStores,
  VocabularyColumn,
} from '../../features/chats/chatView.tsx'
import { useAppModule } from '../../features/appContext.ts'
import { useSnapshot } from 'valtio/react'
import {
  cn,
  isKeyInObject,
  nilMap,
  useUnmount,
} from '../../shared/lib/utils.ts'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { classed } from '@tw-classed/react'
import {
  ChooseLanguageDialog,
  DemoHeader,
} from '../../features/chats/demoChat.tsx'
import { StageProgress } from '../../shared/ui/StageLine/StageProgress.tsx'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppHeader } from '../../features/appHeader/appHeader.tsx'
import { toUppercase } from '../../shared/lib/stringUtils.ts'
import { LessonType, ViewMode } from '../../shared/api/chatApi.ts'

interface LocationState {
  recreateChat: boolean
  lessonType: LessonType
  userVocab: string[]
  mode?: ViewMode
}

export function FullChat(props: { tag: string }) {
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state as LocationState | null
  const recreateChat =
    state !== null && isKeyInObject('recreateChat', state)
      ? state.recreateChat
      : false

  const userVocab =
    state !== null && isKeyInObject('userVocab', state)
      ? { user_vocab: state.userVocab }
      : null

  const viewMode = state?.mode ?? 'full'

  const appModule = useAppModule()
  const isMobile = useIsMobile()

  const chatStoresRef = useLazyRef(() => {
    const chatStore = appModule.chatStore()
    const recordStore = appModule.chatAudioRecordStore(chatStore)
    void chatStore.newChat(
      props.tag,
      recreateChat,
      'lesson',
      viewMode,
      userVocab,
    )
    return {
      chatStore,
      recordStore,
    }
  })

  const chatState = useSnapshot(chatStoresRef.current.chatStore.state)
  const authState = useSnapshot(appModule.authStore.state)

  useUnmount(() => {
    chatStoresRef.current.recordStore.stop()
    appModule.speechService.dispose()
    // navigate(location.pathname, {
    //   state: {
    //     ...location.state,
    //     recreateChat: false,
    //   } as LocationState,
    // })
  })

  //   useEffect(() => {
  //     if (
  //       chatStoresRef.current.chatStore.state.isDemo &&
  //       chatState.showChatBlock
  //     ) {
  //       navigate(urls.demo(), { replace: true })
  //     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [chatState.showChatBlock])

  useEffect(() => {
    if (chatState.url) {
      navigate(chatState.url, {
        replace: true,
      })
    }
  }, [chatState.programTag, chatState.url, chatStoresRef, navigate])

  return (
    <ChatStoreContext.Provider value={chatStoresRef.current}>
      <div className={cn('flex h-dvh w-full flex-col')}>
        {chatState.isDemo && <DemoHeader />}
        <FullChatContainer data-testid="chat-page">
          {chatState.loading == 'chat' || !authState.user ? (
            <Spinner className="m-auto" size="large" />
          ) : (
            <>
              <ChatView />
              {chatState.lessonType === 'lesson' && <VocabularyColumn />}
              {chatState.isDemo && <ChooseLanguageDialog />}
            </>
          )}
        </FullChatContainer>
        <AddWordDrawer />
        {isMobile && <HintView />}
      </div>
    </ChatStoreContext.Provider>
  )
}

export function StageProgressBlock(props: {
  complete: boolean
  className?: string
}) {
  const store = useStores().chatStore
  const chatState = useSnapshot(store.state)
  const appStore = useAppModule().appStore
  const appState = useSnapshot(appStore.state)
  const language = appState.language
  const stages = nilMap(chatState.stages, (x) => x[toUppercase(language)])
  return (
    <AppHeader backButton={!chatState.isDemo} className={props.className}>
      {stages && (
        <div className="mx-auto w-full max-w-800 animate-in fade-in">
          <div
            className={cn(
              'relative mb-16 flex items-center gap-12 text-center text-14 font-bold sm:mb-0 sm:text-left sm:text-20',
              'text-white',
            )}
          >
            {chatState.isDemo ? (
              <FormattedMessage id="Demo lesson" />
            ) : (
              chatState.program?.topic
            )}
          </div>
          <StageProgress
            disabled={props.complete}
            className="mx-auto max-w-800 pr-12 mxs:hidden sm:pr-0"
            stages={stages}
            currentStage={chatState.currentStage}
            onStageSelected={(stage) => {
              document
                .getElementById(messageStageElementId(stage))
                ?.scrollIntoView({ behavior: 'smooth' })
            }}
          />
        </div>
      )}
      {!props.complete && <div className="hidden h-12 w-400 sm:block"></div>}
    </AppHeader>
  )
}

export const FullChatContainer = classed.div(
  'flex w-full flex-1 justify-center gap-24 overflow-hidden sm:rounded-tl-8 sm:bg-white sm:px-36 sm:pt-36 xl:bg-bglight',
)
