import { FormattedMessage } from 'react-intl'
import { AppMessageKeys } from '../translations/messages.ts'
import { Button } from './button/button.tsx'
import { Icon } from './icon/icon.tsx'

export interface IButtonProp {
  handler: () => void
  textId: AppMessageKeys
  bg: 'transparent' | 'black-gradient' | 'blue-gradient' | 'gray-gradient'
  visible: boolean
  withIcon?: boolean
}

interface IBottomButtons {
  firstButtonProps: IButtonProp
  secondButtonProps: IButtonProp
  thirdButtonProps?: IButtonProp
  wrapperClassname?: string
}

export const BottomButtons = ({
  firstButtonProps,
  secondButtonProps,
  thirdButtonProps,
  wrapperClassname,
}: IBottomButtons) => {
  return (
    <div
      className={
        wrapperClassname ??
        'absolute inset-x-16 bottom-16 z-30 flex flex-col gap-12'
      }
    >
      {firstButtonProps.visible && (
        <Button
          rounded="full"
          bg={firstButtonProps.bg}
          size="extralarge"
          onClick={(e) => {
            e.stopPropagation()
            firstButtonProps.handler()
          }}
        >
          <FormattedMessage id={firstButtonProps.textId} />
        </Button>
      )}
      {secondButtonProps.visible && (
        <Button
          size="extralarge"
          rounded="full"
          bg={secondButtonProps.bg}
          className="flex gap-8"
          onClick={(e) => {
            e.stopPropagation()
            secondButtonProps.handler()
          }}
        >
          <FormattedMessage id={secondButtonProps.textId} />
          {secondButtonProps.withIcon && <Icon iconName="arrowRight" />}
        </Button>
      )}
      {thirdButtonProps && thirdButtonProps.visible && (
        <Button
          size="extralarge"
          rounded="full"
          bg={thirdButtonProps.bg}
          className="flex gap-8"
          onClick={(e) => {
            e.stopPropagation()
            thirdButtonProps.handler()
          }}
        >
          <FormattedMessage id={thirdButtonProps.textId} />
          {thirdButtonProps.withIcon && <Icon iconName="arrowRight" />}
        </Button>
      )}
    </div>
  )
}
