import { AppMessageKeys } from '../translations/messages.ts'

export const ADVANCED_LESSON_WITH_CHAT = 'Vocab practice CQ'
export const ADVANCED_LESSON_VOCAB = 'Vocab practice ML'

export const ADVANCED_LESSON_TAGS = [
  ADVANCED_LESSON_VOCAB,
  ADVANCED_LESSON_WITH_CHAT,
]
export const VOCAB_LESSON_TAGS = [
  'Vocab practice MW',
  'Vocab practice MA',
  'Vocab practice MC',
  'Vocab practice CL',
  'Vocab practice ML',
]

// NEED TO DEPRECATE TYPE KEY
export interface MockVocabLesson {
  img: string
  lessonText: string
  lessonType: AppMessageKeys
  lessonTag: string
  tag: string
  type: 'vocab' | 'chat'
}

export const MOCK_VOCAB_LESSONS: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/kimono.png',
    lessonText: 'Match Game',
    lessonType: 'Word-Translation Matching',
    lessonTag: 'Vocab practice MW',
    tag: 'Match',
    type: 'vocab',
  },
  {
    img: '/images/program/lessons/speaker.png',
    lessonType: 'Word-Pronunciation Matching',
    lessonText: 'Sound Game',
    lessonTag: 'Vocab practice MA',
    tag: 'Sound',
    type: 'vocab',
  },
  {
    img: '/images/program/lessons/basketball.png',
    lessonType: 'Multiple choice',
    lessonText: 'Choice Game',
    lessonTag: 'Vocab practice MC',
    tag: 'Choice',
    type: 'vocab',
  },
  {
    img: '/images/program/lessons/teacher.png',
    lessonType: 'Complex Lesson',
    lessonText: 'Big Word Game',
    lessonTag: 'Vocab practice CL',
    tag: 'Big word',
    type: 'vocab',
  },
]

export const MOCK_ADVANCED_LESSON: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/tennis.png',
    lessonType: 'Pick Your Own Words',
    lessonText: 'Play Your Game',
    lessonTag: ADVANCED_LESSON_VOCAB,
    tag: 'Play your game',
    type: 'vocab',
  },
  {
    img: '/images/program/lessons/climber.png',
    lessonType: 'Practice Words in Speech',
    lessonText: 'Context Quest',
    lessonTag: ADVANCED_LESSON_WITH_CHAT,
    tag: 'Context Quest',
    type: 'chat',
  },
]
