import React from 'react'
import { useSnapshot } from 'valtio'
import { TrueOrFalseExerciseStore } from './trueOrFalseExerciseStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import { classed } from '@tw-classed/react'
import { CorrectImage, WrongImage } from '../../../shared/ui/images/images.tsx'
import { ExerciseContainer, ExerciseTitle } from '../exerciseComponents.tsx'
import { TaskSlider } from '../taskSlider/TaskSlider.tsx'
import { nilMap } from '../../../shared/lib/utils.ts'
import { useAppModule } from '../../appContext.ts'
import { TrueOrFalseQuestion, ViewMode } from '../../../shared/api/chatApi.ts'
import { FormattedMessage } from 'react-intl'
import { Payload } from '../../../shared/lib/utils.ts'

interface TrueOrFalseExerciseProps {
  questions: readonly TrueOrFalseQuestion[]
  onComplete: (
    result: string,
    correctAnswersCount: number,
    payload: Payload[],
  ) => void
  viewMode?: ViewMode
}

const AnswerButtonContainer = classed.button(
  'relative col-start-1 row-start-1 flex w-132 flex-col items-center rounded-12 bg-white pb-28 pt-24 shadow-card2 transition-all',
  {
    variants: {
      answer: {
        false: '',
        true: '',
      },
      hasAnswer: {
        true: '',
        false: '',
      },
      currentAnswer: {
        true: 'z-10',
        false: '',
      },
    },
    compoundVariants: [
      {
        answer: true,
        hasAnswer: false,
        className: 'translate-x-68 rotate-[7deg]',
      },
      {
        answer: false,
        hasAnswer: false,
        className: '-translate-x-68 rotate-[-7deg]',
      },
    ],
  },
)

export const TrueOrFalseExercise = (props: TrueOrFalseExerciseProps) => {
  const answerStore = useAppModule().answerStatusStore
  const viewMode = props.viewMode ?? 'full'
  const store = useLazyRef(
    () =>
      new TrueOrFalseExerciseStore(
        props.questions,
        answerStore,
        props.onComplete,
        viewMode,
      ),
  ).current
  const state = useSnapshot(store.state)

  const currentQuestion = state.questions[state.currentIndex]

  return (
    <ExerciseContainer>
      <TaskSlider
        id={state.currentIndex}
        isLast={state.currentIndex == state.questions.length - 1}
      >
        <div className="flex flex-col items-center">
          <ExerciseTitle
            className="mb-24"
            title={<FormattedMessage id="exercise.chooseTrueFalse" />}
          />
          <p className="mb-40 text-16 font-semibold">{currentQuestion.title}</p>
          <div className="relative mb-20 grid">
            <AnswerButton store={store} answer={true} />
            <AnswerButton store={store} answer={false} />
          </div>
        </div>
      </TaskSlider>
    </ExerciseContainer>
  )
}

const AnswerButton = (props: {
  answer: boolean
  store: TrueOrFalseExerciseStore
}) => {
  const state = useSnapshot(props.store.state)
  const currentIndex = state.currentIndex
  const currentAnswer = state.currentAnswer
  const questions = state.questions
  const isCorrect =
    props.answer === currentAnswer
      ? nilMap(state.answerStatus, ({ tag }) => {
          return tag === 'complete'
            ? questions[currentIndex].isCorrect === currentAnswer
            : tag == 'correct'
        }) ?? 'none'
      : 'none'
  const AnswerStatusImage =
    isCorrect == 'none' ? undefined : isCorrect ? CorrectImage : WrongImage

  return (
    <AnswerButtonContainer
      hasAnswer={!!state.answerStatus}
      currentAnswer={!!state.answerStatus && currentAnswer == props.answer}
      answer={props.answer}
      onClick={() => {
        props.store.answerQuestion(props.answer)
      }}
    >
      <div className="mb-28 flex size-60 items-center justify-center rounded-full bg-violet-light2">
        <img src={`/images/exercises/${props.answer}.png`} />
      </div>
      <div className="text-18 font-extrabold">
        {props.answer ? 'True' : 'False'}
      </div>
      {AnswerStatusImage && (
        <AnswerStatusImage className="absolute -right-8 -top-8 animate-in zoom-in" />
      )}
    </AnswerButtonContainer>
  )
}
