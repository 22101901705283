import { proxy } from 'valtio'
import { getLast, shuffleArray } from '../../../shared/lib/utils.ts'
import { AnswerStatus, AnswerStatusStore } from '../answerStatusDrawer.tsx'
import { Payload } from '../../../shared/lib/utils.ts'
import { ViewMode } from '../../../shared/api/chatApi.ts'

export interface ListeningQuestion {
  soundUrl: string
  text: string
  options: readonly string[]
}

interface State {
  questions: readonly ListeningQuestion[]
  wordsForAnswers: readonly string[][]
  words: string[]
  answers: readonly string[]
  userAnswers: { index: number; isCorrect: boolean }[]
  currentAnswer?: { index: number; isCorrect: boolean }
  currentIndex: number
  showAnswer: boolean
  correctAnswersCount: number
  viewMode: ViewMode
}

export class ListeningExerciseStore {
  state: State

  getResult() {
    return this.state.userAnswers
      .map(
        (word, i) =>
          `Answer ${i + 1}. ${this.state.wordsForAnswers[i][word.index]}`,
      )
      .join('\n')
  }
  constructor(
    questions: readonly ListeningQuestion[],
    private answerStore: AnswerStatusStore,
    private onComplete: (
      result: string,
      correctAnswersCount: number,
      payload: Payload[],
    ) => void,
    viewMode: ViewMode,
  ) {
    const answers = questions.map((text) => text.options[0])
    const options = questions.map((text) => text.options)
    this.state = proxy({
      questions,
      showAnswer: false,
      userAnswers: [],
      answers,
      wordsForAnswers: options.map(shuffleArray),
      get words() {
        return this.wordsForAnswers[this.currentIndex]
      },
      currentIndex: 0,
      correctAnswersCount: 0,
      viewMode,
      get currentAnswer() {
        if (this.showAnswer) {
          return getLast(this.userAnswers)
        }
        return undefined
      },
    } as State)
  }

  nextQuestion() {
    if (this.state.currentIndex < this.state.questions.length - 1) {
      this.state.currentIndex += 1
      this.state.showAnswer = false
    } else {
      const getPayload = () => {
        return this.state.wordsForAnswers.reduce<Payload[]>((acc, cur, i) => {
          return [
            ...acc,
            {
              item: cur[this.state.userAnswers[i].index],
              correct: this.state.userAnswers[i].isCorrect,
            },
          ]
        }, [])
      }
      this.onComplete(
        this.getResult(),
        this.state.correctAnswersCount,
        getPayload(),
      )
    }
  }

  select(index: number) {
    if (this.state.showAnswer || this.state.viewMode === 'readonly') {
      return
    }
    const answer = this.state.answers[this.state.currentIndex]
    const userAnswer = this.state.words[index]
    const isCorrect = answer === userAnswer
    isCorrect && this.state.correctAnswersCount++
    this.state.userAnswers.push({ index, isCorrect })
    this.state.showAnswer = true
    this.answerStore.setStatus(
      isCorrect
        ? AnswerStatus.correct()
        : AnswerStatus.wrong({ description: 'Answer:', messageValue: answer }),
      () => {
        this.nextQuestion()
      },
    )
  }
}
