import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, IconName } from '../../shared/ui/icon/icon.tsx'
import { cn } from '../../shared/lib/utils.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { TransparentGradient } from '../../shared/ui/gradient/TransparentGradient.tsx'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { enUS, ru } from 'date-fns/locale'
import { add, format } from 'date-fns'

const BLACK_TEXT = 'text-black'

interface TrialInfoBlock {
  img: IconName
  imgBg: string
  title: AppMessageKeys
  desc: AppMessageKeys
  titleColor: string
  descColor: string
}

const TRIAL_INFO_BLOCK = [
  {
    img: 'checkGreen2' as IconName,
    imgBg: 'bg-teal-light',
    titleColor: BLACK_TEXT,
    descColor: BLACK_TEXT,
    title: 'installTheApp' as AppMessageKeys,
    desc: 'trialInfoDesc1' as AppMessageKeys,
  },
  {
    img: 'lockOpen' as IconName,
    imgBg: 'bg-teal-light',
    titleColor: BLACK_TEXT,
    descColor: BLACK_TEXT,
    title: 'today' as AppMessageKeys,
    desc: 'trialInfoDesc2' as AppMessageKeys,
  },
  {
    img: 'ring' as IconName,
    imgBg: 'bg-blue2',
    titleColor: 'text-blue2',
    descColor: 'text-blue2',
    title: 'day5' as AppMessageKeys,
    desc: 'trialInfoDesc3' as AppMessageKeys,
  },
  {
    img: 'star' as IconName,
    imgBg: 'bg-gray9',
    titleColor: BLACK_TEXT,
    descColor: BLACK_TEXT,
    title: 'day7' as AppMessageKeys,
    desc: 'trialInfoDesc4' as AppMessageKeys,
  },
]

export function TrialInfoView() {
  const navigate = useNavigate()
  return (
    <div className="mx-auto mb-100 flex max-w-800 flex-col items-center justify-center px-24 py-8">
      <h1 className="text-center text-30 font-extrabold text-black">
        <FormattedMessage id="trialInfoHeaderFirstLine" />
        <br />
        <FormattedMessage id="trialInfoHeaderSecondLine" />
      </h1>
      <p className="mt-24 text-center text-16 text-black">
        <FormattedMessage id="trialInfoSubheader" />
      </p>
      <ProgressTracker />
      <div className="fixed bottom-0 mx-auto w-full max-w-800 bg-white p-16 pt-8">
        <TransparentGradient direction="top" />
        <Button
          bg="blue-gradient-shadow-inset"
          className="w-full"
          size="extralarge"
          rounded="full"
          onClick={() => {
            navigate(urls.subscriptions())
          }}
        >
          <FormattedMessage id="seePlans" />
        </Button>
      </div>
    </div>
  )
}

const ProgressTracker = () => {
  return (
    <div className="relative mx-auto mt-40 flex h-[450px] flex-col  items-start">
      <div className="absolute left-12 top-16 -z-10 h-full w-16 bg-trialinfo-gradient" />
      <div className="flex h-full flex-col">
        {TRIAL_INFO_BLOCK.map((el, index) => (
          <TrackerElement key={`trial-info-${index}`} el={el} />
        ))}
      </div>
    </div>
  )
}

const locales = {
  en: enUS,
  ru: ru,
}

const TrackerElement = ({ el }: { el: TrialInfoBlock }) => {
  const intl = useIntl()
  const locale = intl.locale.split('-')[0] as 'en' | 'ru'
  const getDate = () => {
    const selectedLocale = locales[locale]
    const today = new Date()
    const futureDate = add(today, { days: 7 })

    return format(
      futureDate,
      locale === 'en' ? 'MMM dd, yyyy' : 'dd MMM yyyy',
      { locale: selectedLocale },
    )
  }
  return (
    <div className="flex grow gap-12">
      <div
        className={cn(
          'flex size-40 min-w-40 items-center justify-center rounded-full text-white',
          el.imgBg,
        )}
      >
        <Icon iconName={el.img} size="xsm" />
      </div>
      <div className="flex flex-col gap-4">
        <p
          className={cn(
            'text-22 font-bold',
            el.title === 'installTheApp' ? 'line-through' : '',
            el.titleColor,
          )}
        >
          <FormattedMessage id={el.title} />
        </p>
        <p className={cn('text-16 font-semibold', el.descColor)}>
          <FormattedMessage
            id={el.desc}
            values={
              el.desc === 'trialInfoDesc4'
                ? {
                    date: getDate(),
                  }
                : {}
            }
          />
        </p>
      </div>
    </div>
  )
}
