import { useSnapshot } from 'valtio'
import { useProfileStore } from './ProfileStore.ts'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { FormattedMessage } from 'react-intl'
import { useEffect, useState } from 'react'

export const ProfileManageAccount = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)

  return (
    <div>
      <ProfileTitle title={<FormattedMessage id="user.manageAccount" />} />
      <div className="flex flex-col gap-16">
        <ProfileMenuBlock>
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('name')
            }}
            title={state.name}
            subtitle={<FormattedMessage id="user.name" />}
          />
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('email')
            }}
            title={state.email}
            subtitle="Email"
          />
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('password')
            }}
            title="********"
            subtitle={<FormattedMessage id="user.password" />}
          />
        </ProfileMenuBlock>
        <ProfileMenuBlock>
          <ProfileButton
            alertTitle
            onClick={() => {
              store.setShowDeleteAccount(true)
            }}
            title={<FormattedMessage id="user.deleteAcc" />}
            subtitle={<FormattedMessage id="user.thisIsPermanent" />}
          />
        </ProfileMenuBlock>
        <div className="px-16 text-16 text-light">
          <FormattedMessage id="user.deleteNote" />
        </div>
      </div>
      <EditDrawer />
      <DeleteAccountDrawer />
    </div>
  )
}

function EditDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const title = `Change ${state.editField}`

  const [editValue, setEditValue] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value)
    store.setEditValue(e.target.value)
  }

  useEffect(() => {
    setEditValue(state.editValue)
  }, [state.editValue])

  return (
    <ProfileDrawer open={!!state.editField} title={title}>
      <div className="flex flex-col">
        <TextInput
          size="large"
          type={state.editField === 'password' ? 'password' : 'text'}
          minLength={state.editField === 'password' ? 5 : undefined}
          value={editValue}
          onChange={handleChange}
          className="mb-24"
        />
        <Button
          rounded="full"
          size="large"
          bg="blue-gradient"
          onClick={() => {
            void store.saveAccountChanges()
          }}
          className="mb-16"
        >
          <FormattedMessage id="save" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.closeDrawer()
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}

function DeleteAccountDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)

  return (
    <ProfileDrawer
      title={<FormattedMessage id="user.deleteAcc?" />}
      open={state.showDeleteAccount}
    >
      <div className="flex flex-col gap-16">
        <div>
          <FormattedMessage id="user.drawer.deleteTitle" />
          <br />
          <br />
          <FormattedMessage id="user.drawer.deleteText" />
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            void store.deleteAccount()
          }}
        >
          <FormattedMessage id="user.deleteAcc" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowDeleteAccount(false)
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}
