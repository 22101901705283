import { useNavigate } from 'react-router-dom'
import { ContactsView } from '../features/contacts/contactsView.tsx'
import { Button } from '../shared/ui/button/button.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'

export function ContactsPage() {
  const navigate = useNavigate()
  return (
    <div className="relative flex size-full flex-col justify-center pt-48">
      <Button
        size="custom"
        bg="transparent"
        className="absolute left-16 top-16"
        onClick={() => { navigate(-1); }}
      >
        <Icon iconName="arrowLeft" />
      </Button>
      <ContactsView />
    </div>
  )
}
